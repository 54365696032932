import React, { useRef } from "react"
import FullCalendar from '@fullcalendar/react'
import interactionPlugin from '@fullcalendar/interaction'; // for selectable
import dayGridPlugin from '@fullcalendar/daygrid'
import { Container, Row, Col } from "reactstrap"
import styled, { css } from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { media } from "utils/Media"
import arrow from "images/arrow-back.png"
import arrowPrev from "images/arrow-back-prev.png"
import winterGardenTheatre from "images/new-york/winter-garden-theatr-small.svg";
import NewSaleTicketBlock from "../new-york/NewSaleTicketBlock";

const ContainerStyled = styled(Container)`
  padding-top: 2rem;

  .best-available-key {
    font-size: ${props => props.theme.font.size.md};
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      display: inline-block;
      height: 30px;
      width: 30px;
      margin-right: .5rem;
      background: ${props => props.theme.colors.primary};
    }
  }

  .fc  {
    .fc-scrollgrid,
    .fc-scrollgrid-section th,
    .fc-theme-standard td, .fc-theme-standard th {
      border: 0;
    }

    .fc-daygrid-day-frame {
      position: relative;
      z-index: 5;
    }

    .fc-col-header-cell-cushion {
      color: black;
      text-transform: uppercase;
      text-decoration: none;
      font-size: ${props => props.theme.font.size.sm};

      @media ${media.md} {
        font-size: ${props => props.theme.font.size.md};
      }
    }

    .fc-toolbar-title {
      font-size: 2rem;
    }

    .fc-col-header-cell {
      border: 0;
    }

    .fc-toolbar {
      border-bottom: 3px solid ${props => props.theme.colors.primary};
      margin-bottom: 0;
      padding-bottom: 1rem;
    }

    .fc-next-button,
    .fc-prev-button {
      background: transparent;
      border: 0;
      color: black;
      background-image: url("${arrow}");
      background-position: center;
      box-shadow: none;

      &:focus,
      &:active {
        background-color: transparent;
        outline: 0;
        box-shadow: none !important;
      }

      span {
        opacity: 0;
      }
    }

    .fc-prev-button {
      background: transparent;
      border: 0;
      color: black;
      background-image: url("${arrowPrev}");
    }

    .fc-view-harness {
      border-top: 3px solid ${props => props.theme.colors.secondary};
      padding-top: 1rem;
    }

    .fc-event {
      display: flex;
      justify-content: center;
      font-weight: normal;
      color: black;
      font-size: ${props => props.theme.font.size.xs};

      @media ${media.md} {
        font-size: ${props => props.theme.font.size.md};
      }

      
    }

    .fc-daygrid-day {
      @media ${media.md} {
        height: 125px;
      }
    }

    .fc-daygrid-day.fc-day-today {
      border: 2px solid black;
      background-color: white;
    }

    .fc-daygrid-day-number {
      color: black;
      font-weight: bold;
      text-decoration: none;
    }

    .fc-day-other {
      position: relative;
      background-color: rgba(204, 204, 204, .4);
      /* &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: #ccc;
        opacity: .4;
        z-index: 10;
      } */
    }

    .fc-day-past,
    .fc-day-disabled {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        width: 25%;
        height: 2px;
        background: black;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .fc-day-disabled {
      &:after {
        opacity: 0.3;
      }
    }

    .highlighted {
      width: 100%;
      text-align: center;
      font-weight: bold;
      background: ${props => props.theme.colors.black};
      color: ${props => props.theme.colors.orange};
    }
  }

  .fc-theme-standard td {
    border: 1px solid ${props => props.theme.colors.primary};
  }
`

const Box = styled.div`
  background: linear-gradient(to bottom, ${props => props.theme.colors.red}, ${props => props.theme.colors.yellow});
  padding: 3px;
  margin: 0 auto 1rem auto;
  text-align: center;
  max-width: 700px;

  .helper {
    background: ${props => props.theme.colors.white};
    height: 100%;
    padding: 1rem;
  }
`

const CustomBox = styled.div`
  background: linear-gradient(to bottom,
  ${(props) => props.theme.colors.red},
  ${(props) => props.theme.colors.yellow});
  padding: 3px;
  display: block;
  max-width: 750px;
  margin: 0 auto 1rem auto;

  div {
    background: ${(props) => props.theme.colors.black};
    padding: .5rem 1.5rem;
    position: relative;
    color: ${(props) => props.theme.colors.white};
    font-size: ${(props) => props.theme.font.size.md};
    font-style: italic;

    p a {
      color: ${(props) => props.theme.colors.white};
    }

    strong {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  ${props => props.$bold && css`
    font-weight: 900;
  `}
`;


export default function TicketCalendar() {
  const calendarRef = useRef(null);

  const data = useStaticQuery(graphql`
    query Dates {
      allGoogleSheet {
        edges {
          node {
            events: Sheet1 {
              title
              start
              bestAvailable
              highlighted
              url
            }
          }
        }
      }
    }
  `)

  console.log(data)


// const handleDayCellClassNames = (arg) => {
//   const dateStr = arg.date.toISOString().split('T')[0];
//   const startDate = new Date('2024-09-17');
//   const endDate = new Date('2024-11-10');
//   const exclusions = ['2024-09-23'];
//   // const exclusions = ['2024-10-11', '2024-10-12', '2024-10-13', '2024-10-05'];

//   const currentDate = arg.date;
  
//   if (currentDate >= startDate && currentDate <= endDate && !exclusions.includes(dateStr)) {
//     return ['custom-date-style'];
//   }
//   return [];
// };

  const { events } = data.allGoogleSheet?.edges[0]?.node
  let eventsArr = []

  events.forEach(event => {
    if (!event.title || !event.start) return null

    const dateString = event.start
    const timeString = event.title

    const [day, month, year] = dateString.split("/");
    const monthIndex = parseInt(month, 10) - 1;

    const [hours, minutes, period] = timeString.split(/:|([ap]m)/gi).filter(Boolean);
    let adjustedHours = parseInt(hours, 10);
    
    if (period && period.toLowerCase() === "pm" && adjustedHours < 12) {
      adjustedHours += 12;
    } else if (period && period.toLowerCase() === "am" && adjustedHours === 12) {
      adjustedHours = 0;
    }

    const formattedDate = new Date(year, monthIndex, day, adjustedHours.toString(), minutes);

    if (formattedDate.getTime() > new Date().getTime()) {
      eventsArr.push({
        title: event.title,
        start: formattedDate,
        url: event.url,
        extendedProps: {
          bestAvailable: event.bestAvailable,
          highlighted: event.highlighted
        }
      })
    } 
  })

  const startDate = new Date(eventsArr[0].start.getFullYear(), eventsArr[0].start.getMonth(), 1);
  const endDate = new Date(eventsArr[eventsArr.length - 1].start);

  const handleEventClick = (info) => {
    info.jsEvent.preventDefault();
    if (info.event.url) {
      window.open(info.event.url, '_blank');
    }
  };

  return (
    <ContainerStyled>
      
    <NewSaleTicketBlock />
      <Row>
        <Col xs={12} className="mb-4">
          {/* <Row className="justify-content-md-between align-items-center  pb-4">
            <Col xs={12} md="auto" className="text-center text-md-left">
              <CustomBox $bold>
                <div>TICKETS FROM $74 ($59 + $15 fee)</div>
              </CustomBox>
            </Col>

            {/* <Col xs={12} md="auto">
              <div className="best-available-key">
                <span />
                Best availability
              </div>
            </Col> */}
          {/* </Row> */}

          {eventsArr &&
            <FullCalendar
              ref={calendarRef}
              plugins={[dayGridPlugin]}
              initialView='dayGridMonth'
              // weekends={false}
              firstDay={0}
              events={eventsArr}
              eventContent={renderEventContent}

              // dayCellClassNames={handleDayCellClassNames}

              eventClick={handleEventClick}
              initialDate={eventsArr[0].start}
              validRange={{ start: startDate, end: endDate }}
              contentHeight="auto"
              headerToolbar={{
                left: 'prev', // Remove the "today" button from the left section
                center: 'title',
                right: 'next',
              }}
              fixedWeekCount={false}
            />
          }
        </Col>
      </Row>
      <Row className="justify-content-md-between align-items-center">
        <Col xs={12}  className="text-center">
          <h3 className="pb-3 uppercase">booking through April 27, 2025</h3>

          <Row>
            <Col xs={12} lg={4}>
              <Box>
                <div className="helper">
                  <h3 className="mb-0">ONLINE</h3>
                  <p>Choose a date on the calendar or <a href="https://www.telecharge.com/Broadway/Back-to-the-Future-the-Musical/Overview?AID=BWY001390400&utm_source=show_site&utm_campaign=Back-to-the-Future-the-MusicalSS&utm_medium=web/" target="_blank" rel="noopener ">click here</a></p>
                </div>
              </Box>
            </Col>
            <Col xs={12} lg={4}>
              <Box>
                <div className="helper">
                  <h3 className="mb-0">IN PERSON</h3>
                  <p className="pb-3">
                    <img src={winterGardenTheatre} alt="Winter Garden Theatre" width="40" /> Winter Garden Theatre, 1634 Broadway, New York, NY 10019<br/>
                    Between West 50th and 51st Streets
                  </p>
                </div>
              </Box>
            </Col>
            <Col xs={12} lg={4}> 
              <Box>
                <div className="helper">
                  <h3 className="mb-0">BY PHONE</h3>
                  <p>212-239-6200</p>
                </div>
              </Box>
            </Col>
          </Row>
        </Col>
      </Row>
    </ContainerStyled>
  )
}

// a custom render function
function renderEventContent(eventInfo) {
  return (
    <>
      <span className={`${eventInfo.event.extendedProps.bestAvailable ? 'best-available' : ''} ${eventInfo.event.extendedProps.highlighted ? 'highlighted' : ''}`}>{eventInfo.event.title}</span>
    </>
  )
}